import { GenericError } from '../api';

export interface HttpClientError extends Error {
    body: {
        status: number;
        message: string;
        name: string;
        stack?: string;
        errors?: Array<GenericError>;
    };
}

export function isHttpClientError(err: unknown): err is HttpClientError {
    return (
        typeof err === 'object' &&
        err !== null &&
        'body' in err &&
        typeof err.body === 'object' &&
        err.body !== null &&
        'status' in err.body &&
        typeof err.body.status === 'number' &&
        'message' in err.body &&
        typeof err.body.message === 'string' &&
        'name' in err.body &&
        typeof err.body.name === 'string'
    );
}
