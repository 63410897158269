/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NavItem } from '../models/NavItem';
import type { NavItemActionCreate } from '../models/NavItemActionCreate';
import type { NavItemActionPatch } from '../models/NavItemActionPatch';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NavItemService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get nav item by contextID
     * Return a list of navigation items for a given contextID
     * @returns NavItem Success
     * @throws ApiError
     */
    public listNavItemByDomainId({
        contextId,
        isMain,
        parentId,
        cache,
    }: {
        contextId: number,
        isMain?: boolean,
        parentId?: number,
        cache?: boolean,
    }): CancelablePromise<Array<NavItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/nav-item',
            path: {
                'contextID': contextId,
            },
            query: {
                'isMain': isMain,
                'parentID': parentId,
                'cache': cache,
            },
        });
    }
    /**
     * Create a new navigation item
     * Creates and returns a new navigation item
     *
     * - Allowed roles: moderator, editor, admin
     * @returns NavItem Created
     * @throws ApiError
     */
    public createNavItemByDomainId({
        contextId,
        requestBody,
    }: {
        contextId: number,
        requestBody: NavItemActionCreate,
    }): CancelablePromise<NavItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/nav-item',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update an existing navigation item
     * Updates and returns the edited navigation item
     *
     * - Allowed roles: moderator, editor, admin
     * @returns NavItem Success
     * @throws ApiError
     */
    public patchNavItemByDomainId({
        contextId,
        navItemId,
        requestBody,
    }: {
        contextId: number,
        navItemId: number,
        requestBody: NavItemActionPatch,
    }): CancelablePromise<NavItem> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/nav-item/{navItemID}',
            path: {
                'contextID': contextId,
                'navItemID': navItemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Delete an existing navigation item
     * Deletes and returns 204 or 404 depending if the request succeeds
     *
     * - Allowed roles: moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteNavItemByDomainId({
        contextId,
        navItemId,
    }: {
        contextId: number,
        navItemId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/nav-item/{navItemID}',
            path: {
                'contextID': contextId,
                'navItemID': navItemId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
