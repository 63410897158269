/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ImageSize {
    V2_FEATURED = 'v2_featured',
    V2_LARGE = 'v2_large',
    XL = 'xl',
    LARGE = 'large',
    QUATTRO = 'quattro',
    WC = 'wc',
    THUMB = 'thumb',
    VID = 'vid',
    VID_LARGE = 'vid_large',
    SOCIAL = 'social',
}
