/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Order } from '../models/Order';
import type { Pagination } from '../models/Pagination';
import type { Scope } from '../models/Scope';
import type { VideofeedItem } from '../models/VideofeedItem';
import type { VideofeedItemActionCreate } from '../models/VideofeedItemActionCreate';
import type { VideofeedItemActionCreateResponseScopeAdmin } from '../models/VideofeedItemActionCreateResponseScopeAdmin';
import type { VideofeedItemActionPatch } from '../models/VideofeedItemActionPatch';
import type { VideofeedItemActionUpdateResponseScopeAdmin } from '../models/VideofeedItemActionUpdateResponseScopeAdmin';
import type { VideofeedOrderBy } from '../models/VideofeedOrderBy';
import type { VideofeedStatus } from '../models/VideofeedStatus';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class VideofeedService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all videofeed items.
     * Return a list of videofeed items for a given contextID
     * @returns any Success
     * @throws ApiError
     */
    public listVideofeedByDomainId({
        contextId,
        scope,
        status,
        search,
        orderBy,
        order,
        page = 1,
        perPage = 50,
        cache,
    }: {
        contextId: number,
        scope?: Scope,
        status?: VideofeedStatus,
        search?: string,
        orderBy?: VideofeedOrderBy,
        order?: Order,
        page?: number,
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<VideofeedItem>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/videofeed',
            path: {
                'contextID': contextId,
            },
            query: {
                'scope': scope,
                'status': status,
                'search': search,
                'orderBy': orderBy,
                'order': order,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
        });
    }
    /**
     * Create new videofeed item.
     * Create a videofeed item and respond with the newly created item.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns VideofeedItemActionCreateResponseScopeAdmin Created
     * @throws ApiError
     */
    public createVideofeedByDomainId({
        contextId,
        requestBody,
        cache,
    }: {
        contextId: number,
        requestBody: VideofeedItemActionCreate,
        cache?: boolean,
    }): CancelablePromise<VideofeedItemActionCreateResponseScopeAdmin> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/videofeed',
            path: {
                'contextID': contextId,
            },
            query: {
                'cache': cache,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Patch existing videofeed item.
     * Patch existing videofeed item and respond with the updated item.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns VideofeedItemActionUpdateResponseScopeAdmin Success
     * @throws ApiError
     */
    public patchVideofeedByDomainId({
        contextId,
        videofeedId,
        requestBody,
        cache,
    }: {
        contextId: number,
        videofeedId: number,
        requestBody: VideofeedItemActionPatch,
        cache?: boolean,
    }): CancelablePromise<VideofeedItemActionUpdateResponseScopeAdmin> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/videofeed/{videofeedID}',
            path: {
                'contextID': contextId,
                'videofeedID': videofeedId,
            },
            query: {
                'cache': cache,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
