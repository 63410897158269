/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ConfigKey {
    JWPLAYER_AD_SCHEDULE = 'jwplayer-ad-schedule',
    JWPLAYER_MIN_SEC = 'jwplayer-min-sec',
    JWPLAYER_VAST_TAGS = 'jwplayer-vast-tags',
    JWPLAYER_GTAG = 'jwplayer-gtag',
    JWPLAYER_XANDR = 'jwplayer-xandr',
    JWPLAYER_GPS = 'jwplayer-gps',
    JWPLAYER_IOS_AD_SCHEDULE = 'jwplayer-ios-ad-schedule',
    JWPLAYER_IOS_MIN_SEC = 'jwplayer-ios-min-sec',
    JWPLAYER_IOS_VAST = 'jwplayer-ios-vast',
    JWPLAYER_IOS_GTAG = 'jwplayer-ios-gtag',
    JWPLAYER_IOS_XANDR = 'jwplayer-ios-xandr',
    JWPLAYER_IOS_GPS = 'jwplayer-ios-gps',
    JWPLAYER_ANDROID_AD_SCHEDULE = 'jwplayer-android-ad-schedule',
    JWPLAYER_ANDROID_MIN_SEC = 'jwplayer-android-min-sec',
    JWPLAYER_ANDROID_VAST = 'jwplayer-android-vast',
    JWPLAYER_ANDROID_GTAG = 'jwplayer-android-gtag',
    JWPLAYER_ANDROID_XANDR = 'jwplayer-android-xandr',
    JWPLAYER_ANDROID_GPS = 'jwplayer-android-gps',
    MOBILE_FORCE_UPDATE_IOS = 'mobile-force-update-ios',
    MOBILE_FORCE_UPDATE_ANDROID = 'mobile-force-update-android',
    G_RECAPTCHA_THRESHOLD = 'g-recaptcha-threshold',
    IP_QS = 'ip-qs',
}
