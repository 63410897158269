/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CuratedNewsLayout {
    EXPANDED_SIDE = 'expandedSide',
    MINIFIED_SIDE = 'minifiedSide',
    TWIN_MAINS = 'twinMains',
    EXPANDED_FOOTER = 'expandedFooter',
}
