/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeaturedCarousel } from '../models/FeaturedCarousel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FeaturedCarouselService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get headline items for a given contextID
     * Get headline items for a given contextID
     * @returns FeaturedCarousel Success
     * @throws ApiError
     */
    public getFeaturedCarouselByDomainId({
        contextId,
    }: {
        contextId: number,
    }): CancelablePromise<FeaturedCarousel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/featured-carousel',
            path: {
                'contextID': contextId,
            },
        });
    }
}
