/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PlayerPosition {
    UNKNOWN = 'unknown',
    ATTACKER = 'attacker',
    MIDFIELDER = 'midfielder',
    DEFENDER = 'defender',
    GOALKEEPER = 'goalkeeper',
}
