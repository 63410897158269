import './NextJsonLayout';

import { logger as base } from './logger';
import { defaultLogLevels } from './logLevel';

base.appenders.clear();
base.appenders.set('console-log', {
    type: 'console',
    levels: defaultLogLevels,
    layout: {
        type: 'next-json',
    },
});

export const logger = base;
