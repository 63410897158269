import { AxiosRequestConfig } from 'axios';

import { ApiBaseRequest } from '@common/clients/request';
import { ContextData } from '@common/defaults';

interface ServiceArgs {
    contextData: ContextData;
    isClientSide?: boolean;
    config?: AxiosRequestConfig;
}
interface ApiServiceArgs<T> extends ServiceArgs {
    service: new (a: ApiBaseRequest) => T;
}

export function ApiService<T>({ contextData, isClientSide = true, config, service }: ApiServiceArgs<T>): T {
    const request = new ApiBaseRequest(contextData, isClientSide, config);
    return new service(request);
}
