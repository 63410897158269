/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum VideofeedVendor {
    CUSTOM = 'custom',
    FOXSPORT = 'foxsport',
    OMNISPORT = 'omnisport',
    ESPN = 'espn',
    KNVB = 'knvb',
    VIRAL = 'viral',
    ADVERTORIAL = 'advertorial',
}
