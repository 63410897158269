import type { AxiosRequestConfig } from 'axios';

import { BaseHttpRequest, CancelablePromise, PlatformID } from '@common/clients/api';
import { ApiRequestOptions } from '@common/clients/api/core/ApiRequestOptions';
import { request as __request } from '@common/clients/request';
import { ContextData } from '@common/defaults/ContextData';

import { getClient } from './getClient';

export class CrossPlatformApiBaseRequest extends BaseHttpRequest {
    public TIMEOUT: number;
    axiosOptions: AxiosRequestConfig | undefined;

    constructor(
        contextData: ContextData,
        platform: PlatformID,
        isClientSide: boolean,
        axiosOptions?: AxiosRequestConfig,
    ) {
        const config = contextData.config?.crossPlatform?.[platform];

        super({
            BASE: isClientSide || !config.internalHost ? config.host : config.internalHost,
            VERSION: '1.0.0',
            WITH_CREDENTIALS: true,
            CREDENTIALS: isClientSide ? 'same-origin' : 'include',
        });
        this.axiosOptions = axiosOptions;

        this.TIMEOUT = config.timeout || 5000;
    }

    public request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        const extendedConfig = {
            ...this.config,
            TIMEOUT: this.TIMEOUT,
        };
        return __request(extendedConfig, options, getClient(), this.axiosOptions);
    }
}
