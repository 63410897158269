import { FC } from 'react';

import { useContextData } from '@common/useContextData';
import { OneSignalTracker } from '@web/molecules/TrackingHandler/atoms/OneSignalTracker';

import { FacebookPixel } from './atoms/FacebookPixel';
import { GoogleAnalytics } from './atoms/GoogleAnalytics';

interface Props {}

export const TrackingHandler: FC<Props> = () => {
    const { allowTracking } = useContextData();
    return allowTracking === false ? null : (
        <>
            <GoogleAnalytics />
            <FacebookPixel />
            <OneSignalTracker />
        </>
    );
};
