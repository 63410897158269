/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FullContext } from '../models/FullContext';
import type { Platform } from '../models/Platform';
import type { PlatformID } from '../models/PlatformID';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PlatformControllerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get platform by id.
     * @returns Platform Success
     * @throws ApiError
     */
    public getPlatformByPlatform({
        platform,
        cache,
    }: {
        platform: PlatformID,
        cache?: boolean,
    }): CancelablePromise<Platform> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}',
            path: {
                'platform': platform,
            },
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Get contexts for a contextID.
     * @returns FullContext Success
     * @throws ApiError
     */
    public getContextsPlatformByPlatform({
        platform,
        cache,
    }: {
        platform: PlatformID,
        cache?: boolean,
    }): CancelablePromise<Array<FullContext>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/contexts',
            path: {
                'platform': platform,
            },
            query: {
                'cache': cache,
            },
        });
    }
}
