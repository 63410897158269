/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PlayerRole {
    PLAYER = 'player',
    COACH = 'coach',
    ASSISTANT_COACH = 'assistant-coach',
    STAFF = 'staff',
}
