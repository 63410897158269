/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TestEventType {
    PUSH_GOAL = 'PUSH_GOAL',
    PUSH_OWN_GOAL = 'PUSH_OWN_GOAL',
    PUSH_PENALTY_GOAL = 'PUSH_PENALTY_GOAL',
    PUSH_NO_GOAL = 'PUSH_NO_GOAL',
    PUSH_YELLOW_CARD = 'PUSH_YELLOW_CARD',
    PUSH_2ND_YELLOW_CARD = 'PUSH_2ND_YELLOW_CARD',
    PUSH_RED_CARD = 'PUSH_RED_CARD',
    PUSH_PENALTY_MISSED = 'PUSH_PENALTY_MISSED',
    PUSH_PENALTY_SHOOTOUT_SCORED = 'PUSH_PENALTY_SHOOTOUT_SCORED',
    PUSH_PENALTY_SHOOTOUT_MISSED = 'PUSH_PENALTY_SHOOTOUT_MISSED',
    PUSH_SUBSTITUTION = 'PUSH_SUBSTITUTION',
    PUSH_STAGE_START = 'PUSH_STAGE_START',
    PUSH_STAGE_END = 'PUSH_STAGE_END',
}
