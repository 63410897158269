import { FC } from 'react';
import Head from 'next/head';

import { FallbackStyleBlock } from './FallbackStyleBlock';

export const Fallback: FC<{}> = () => (
    <Head>
        <FallbackStyleBlock />
    </Head>
);
