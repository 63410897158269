/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Pagination } from '../models/Pagination';
import type { Redirect } from '../models/Redirect';
import type { RedirectActionCreate } from '../models/RedirectActionCreate';
import type { RedirectActionUpdate } from '../models/RedirectActionUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RedirectService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get redirects
     * Return a list of redirects for the current context.
     * @returns any Success
     * @throws ApiError
     */
    public listRedirectByDomainId({
        contextId,
        page = 1,
        perPage = 25,
        cache,
    }: {
        contextId: number,
        /**
         * Current page
         */
        page?: number,
        /**
         * Amount of items per page
         */
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Redirect>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/redirect',
            path: {
                'contextID': contextId,
            },
            query: {
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
        });
    }
    /**
     * Create a redirect
     * Create a redirect and respond with the newly created redirect.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns Redirect Created
     * @throws ApiError
     */
    public createRedirectByDomainId({
        contextId,
        requestBody,
    }: {
        contextId: number,
        requestBody: RedirectActionCreate,
    }): CancelablePromise<Redirect> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/redirect',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get a redirect
     * Returns a single redirect
     *
     * - Allowed roles: moderator, editor, admin
     * @returns Redirect Success
     * @throws ApiError
     */
    public getRedirectByDomainId({
        contextId,
        id,
        cache,
    }: {
        contextId: number,
        /**
         * Get redirect by id
         */
        id: number,
        cache?: boolean,
    }): CancelablePromise<Redirect> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/redirect/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            query: {
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update a redirect
     * Update a redirect and respond with the updated redirect.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns Redirect Success
     * @throws ApiError
     */
    public putRedirectByDomainId({
        contextId,
        id,
        requestBody,
    }: {
        contextId: number,
        /**
         * Get redirect by id
         */
        id: number,
        requestBody: RedirectActionUpdate,
    }): CancelablePromise<Redirect> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/domain/{contextID}/redirect/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Deletes a redirect
     * Deletes and returns 204 or 404 depending if the request succeeds
     *
     * - Allowed roles: moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteRedirectByDomainId({
        contextId,
        id,
    }: {
        contextId: number,
        /**
         * Get redirect by id
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/redirect/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
