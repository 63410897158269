/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AdPlacement {
    ALL_BEFORE_BODY_CLOSURE = 'all-before-body-closure',
    ALL_HEAD = 'all-head',
    HOME_AFTER_MENU = 'home-after-menu',
    HOME_AFTER_CAROUSEL = 'home-after-carousel',
    HOME_BEFORE_CONTENT = 'home-before-content',
    HOME_AFTER_ARTICLE_10 = 'home-after-article-10',
    HOME_AFTER_ARTICLE_40 = 'home-after-article-40',
    HOME_AFTER_PLAYER = 'home-after-player',
    HOME_AFTER_PLAYER_AFTER_ARTICLE_5 = 'home-after-player-after-article-5',
    HOME_SIDEBAR_TOP = 'home-sidebar-top',
    HOME_SIDEBAR_AFTER_SECTION_1 = 'home-sidebar-after-section-1',
    HOME_SIDEBAR_AFTER_SECTION_2 = 'home-sidebar-after-section-2',
    NON_HOME_AFTER_MENU = 'non-home-after-menu',
    NON_HOME_BEFORE_CONTENT = 'non-home-before-content',
    NON_HOME_AFTER_ARTICLE_5 = 'non-home-after-article-5',
    NON_HOME_AFTER_SECTION_1 = 'non-home-after-section-1',
    NON_HOME_AFTER_SECTION_2 = 'non-home-after-section-2',
    NON_HOME_SIDEBAR_TOP = 'non-home-sidebar-top',
    NON_HOME_SIDEBAR_AFTER_SECTION_1 = 'non-home-sidebar-after-section-1',
    NON_HOME_SIDEBAR_AFTER_SECTION_2 = 'non-home-sidebar-after-section-2',
    ARTICLE_AFTER_PARAGRAPH_1 = 'article-after-paragraph-1',
    ARTICLE_AFTER_PARAGRAPH_2 = 'article-after-paragraph-2',
    ARTICLE_AFTER_PARAGRAPH_3 = 'article-after-paragraph-3',
    ARTICLE_AFTER_PARAGRAPH_4 = 'article-after-paragraph-4',
    ARTICLE_AFTER_PARAGRAPH_7 = 'article-after-paragraph-7',
    ARTICLE_AFTER_PARAGRAPH_10 = 'article-after-paragraph-10',
    ARTICLE_AFTER_PARAGRAPH_13 = 'article-after-paragraph-13',
    ARTICLE_AFTER_CONTENT = 'article-after-content',
    ARTICLE_BEFORE_TAGS = 'article-before-tags',
    ARTICLE_AFTER_TAGS = 'article-after-tags',
    ARTICLE_AFTER_COMMENT_5 = 'article-after-comment-5',
    ARTICLE_AFTER_COMMENT_10 = 'article-after-comment-10',
    ARTICLE_AFTER_COMMENT_15 = 'article-after-comment-15',
    ARTICLE_AFTER_COMMENT_20 = 'article-after-comment-20',
    ARTICLE_AFTER_COMMENT_25 = 'article-after-comment-25',
}
