import { FC } from 'react';

import { Ad, AdClient, AdPlacement } from '@common/clients/api';

import { Placement } from './Placement';

import styles from '../Ad.module.scss';

interface Props {
    ad: Ad;
}

export const Wrapper: FC<Props> = ({ ad }) => {
    const classes: string[] = ['ad', styles.ad];

    if ([AdPlacement.HOME_AFTER_MENU, AdPlacement.NON_HOME_AFTER_MENU].indexOf(ad.placement) !== -1) {
        classes.push(styles['has-background']);
    }

    if (ad.wrapperSize) classes.push(styles[ad.wrapperSize]);

    switch (ad.client) {
        case AdClient.MOBILE:
            classes.push(styles['mobile-only']);
            break;
        case AdClient.DESKTOP:
            classes.push(styles['desktop-only']);
            break;
    }

    return (
        <aside className={classes.join(' ')} data-nosnippet>
            <Placement ad={ad} />
        </aside>
    );
};
