import { FC } from 'react';

import { Ad, Props } from '../Ad';

// Optional ads need a div around so react knows where to place them on rerender
export const OptionalAd: FC<Props> = (props) => (
    <div key={props.placement}>
        <Ad {...props} isOptional={false}></Ad>
    </div>
);
