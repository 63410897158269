/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MyInfo } from '../models/MyInfo';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SuperSecretService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @deprecated
     * This is for testing purposes only. It will be removed in the future.
     * @returns MyInfo Success
     * @throws ApiError
     */
    public myInfoSuperSecret(): CancelablePromise<MyInfo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/super-secret/my-info',
        });
    }
    /**
     * @deprecated
     * This is for testing purposes only. It will be removed in the future.
     * @returns void
     * @throws ApiError
     */
    public adminRoleOnlySuperSecret(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/super-secret/admin-role-only',
        });
    }
}
