/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Match } from '../models/Match';
import type { Order } from '../models/Order';
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MatchService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get matches list for date range
     * Get all matches played in specified dates for competitions, that are configured in the admin system. Use timespan not longer than 7 days.
     * @returns any Success
     * @throws ApiError
     */
    public getForSpecifiedDatesMatch({
        startDate,
        endDate,
        bookmakerId,
        secondBookmakerId,
        order,
        page,
        perPage,
    }: {
        /**
         * Start date of matches to be returned. String in format yyyy-MM-dd.
         */
        startDate: string,
        /**
         * End date of matches to be returned. String in format yyyy-MM-dd.
         */
        endDate: string,
        /**
         * Bookmaker ID
         */
        bookmakerId?: number,
        /**
         * Second bookmaker ID
         */
        secondBookmakerId?: number,
        order?: Order,
        page?: number,
        perPage?: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Match>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/match',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'bookmakerID': bookmakerId,
                'secondBookmakerID': secondBookmakerId,
                'order': order,
                'page': page,
                'perPage': perPage,
            },
        });
    }
    /**
     * Get basic info of a given match
     * Get basic info of a given match
     * @returns Match Success
     * @throws ApiError
     */
    public getBySlugMatch({
        kickOffDate,
        slug,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Kick off date of the requested match. String in format yyyy-MM-dd.
         */
        kickOffDate: string,
        /**
         * Slug of the requested match
         */
        slug: string,
        /**
         * Bookmaker ID
         */
        bookmakerId?: number,
        /**
         * Second bookmaker ID
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Match> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/match/slug/{kickOffDate}/{slug}',
            path: {
                'kickOffDate': kickOffDate,
                'slug': slug,
            },
            query: {
                'bookmakerID': bookmakerId,
                'secondBookmakerID': secondBookmakerId,
            },
        });
    }
    /**
     * Get details of a given match
     * Get details of a given match
     * @returns Match Success
     * @throws ApiError
     */
    public getMatchDetailsMatch({
        id,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * OPTA ID of the requested match
         */
        id: string,
        /**
         * Bookmaker ID
         */
        bookmakerId?: number,
        /**
         * Second bookmaker ID
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Match> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/match/{id}/detail',
            path: {
                'id': id,
            },
            query: {
                'bookmakerID': bookmakerId,
                'secondBookmakerID': secondBookmakerId,
            },
        });
    }
    /**
     * Get details of a given match
     * Get details of a given match
     * @returns Match Success
     * @throws ApiError
     */
    public getMatchDetailsBySlugMatch({
        kickOffDate,
        slug,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Kick off date of the requested match. String in format yyyy-MM-dd.
         */
        kickOffDate: string,
        /**
         * Slug of the requested match
         */
        slug: string,
        /**
         * Bookmaker ID
         */
        bookmakerId?: number,
        /**
         * Second bookmaker ID
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Match> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/match/slug/{kickOffDate}/{slug}/detail',
            path: {
                'kickOffDate': kickOffDate,
                'slug': slug,
            },
            query: {
                'bookmakerID': bookmakerId,
                'secondBookmakerID': secondBookmakerId,
            },
        });
    }
    /**
     * Search matches close to the current date (returns max 8 results)
     * - Allowed roles: moderator, editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public searchMatch({
        search,
    }: {
        search: string,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Match>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/match/search',
            query: {
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get matches list for tournament
     * @returns any Success
     * @throws ApiError
     */
    public getForTournamentMatch({
        tournamentId,
        startDate,
        endDate,
        bookmakerId,
        secondBookmakerId,
        order,
        page,
        perPage,
    }: {
        /**
         * Tournament that matches should be returned from.
         */
        tournamentId: string,
        startDate: string,
        endDate: string,
        bookmakerId?: number,
        secondBookmakerId?: number,
        order?: Order,
        page?: number,
        perPage?: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Match>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/match/tournament/{tournamentID}',
            path: {
                'tournamentID': tournamentId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'bookmakerID': bookmakerId,
                'secondBookmakerID': secondBookmakerId,
                'order': order,
                'page': page,
                'perPage': perPage,
            },
        });
    }
    /**
     * Get matches list for team
     * @returns any Success
     * @throws ApiError
     */
    public getForTeamMatch({
        teamId,
        startDate,
        endDate,
        bookmakerId,
        secondBookmakerId,
        order,
        page,
        perPage,
    }: {
        /**
         * Team whose matches should be returned
         */
        teamId: string,
        startDate: string,
        endDate: string,
        /**
         * Bookmaker ID
         */
        bookmakerId?: number,
        /**
         * Second bookmaker ID
         */
        secondBookmakerId?: number,
        order?: Order,
        page?: number,
        perPage?: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Match>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/match/team/{teamID}',
            path: {
                'teamID': teamId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'bookmakerID': bookmakerId,
                'secondBookmakerID': secondBookmakerId,
                'order': order,
                'page': page,
                'perPage': perPage,
            },
        });
    }
    /**
     * Get matches list by Opta IDs
     * Get matches list by Opta IDs
     * @returns Match Success
     * @throws ApiError
     */
    public getByIDsMatch({
        ids,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * List of Opta match IDs
         */
        ids?: Array<string>,
        /**
         * Bookmaker ID
         */
        bookmakerId?: number,
        /**
         * Second bookmaker ID
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<Match>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/match/ids',
            query: {
                'ids': ids,
                'bookmakerID': bookmakerId,
                'secondBookmakerID': secondBookmakerId,
            },
        });
    }
    /**
     * Get upcoming matches with odds for teams
     * Get upcoming matches with odds based on provided opta ids
     * @returns Match Success
     * @throws ApiError
     */
    public getMatchesWithOddsMatch({
        optaIDs,
        limit = 2,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Filter tags by multiple team optaIDs.
         */
        optaIDs?: Array<string>,
        /**
         * amount of matches
         */
        limit?: number,
        /**
         * Bookmaker ID
         */
        bookmakerId?: number,
        /**
         * Second bookmaker ID
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<Match>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/match/with-odds',
            query: {
                'optaIDs': optaIDs,
                'limit': limit,
                'bookmakerID': bookmakerId,
                'secondBookmakerID': secondBookmakerId,
            },
        });
    }
    /**
     * Get basic info of a given match
     * Get basic info of a given match
     * @returns Match Success
     * @throws ApiError
     */
    public getByIdMatch({
        id,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * OPTA ID of the requested match
         */
        id: string,
        /**
         * Bookmaker ID
         */
        bookmakerId?: number,
        /**
         * Second bookmaker ID
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Match> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/match/{id}',
            path: {
                'id': id,
            },
            query: {
                'bookmakerID': bookmakerId,
                'secondBookmakerID': secondBookmakerId,
            },
        });
    }
}
