/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum UserRole {
    USER_NOT_VALIDATED = 'user-not-validated',
    USER = 'user',
    MODERATOR = 'moderator',
    EDITOR = 'editor',
    ADMIN = 'admin',
    USER_BANNED = 'user-banned',
}
