/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum StatType {
    TOTAL_SHOTS = 'total-shots',
    SHOTS_ON_GOAL = 'shots-on-goal',
    FOULS = 'fouls',
    CORNERS = 'corners',
    OFFSIDES = 'offsides',
    POSSESSION_PERCENTAGE = 'possession-percentage',
    EXPECTED_GOALS = 'expected-goals',
}
