/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Competition } from '../models/Competition';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CompetitionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get competition
     * Get competition
     * @returns Competition Success
     * @throws ApiError
     */
    public getCompetition({
        id,
    }: {
        /**
         * OPTA ID of the competition or OPTA ID of the one of the tournaments from the competition.
         */
        id: string,
    }): CancelablePromise<Competition> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/competition/by-tag/{id}',
            path: {
                'id': id,
            },
        });
    }
}
