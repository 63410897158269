/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExperimentGroup } from '../models/ExperimentGroup';
import type { ExperimentGroupOrRobots } from '../models/ExperimentGroupOrRobots';
import type { Image } from '../models/Image';
import type { News } from '../models/News';
import type { NewsActionCreate } from '../models/NewsActionCreate';
import type { NewsStatus } from '../models/NewsStatus';
import type { Pagination } from '../models/Pagination';
import type { Scope } from '../models/Scope';
import type { VideofeedVendor } from '../models/VideofeedVendor';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NewsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get recent news articles
     * Return a list of news articles for a given contextID created in the last year or filtered by title
     * @returns any Success
     * @throws ApiError
     */
    public listNewsByDomainId({
        contextId,
        scope,
        tagId,
        userId,
        authorUserId,
        newsStatus,
        search,
        isMedia,
        vendor,
        newsCatId,
        title,
        year,
        month,
        page = 1,
        perPage = 20,
        checkNextPage,
        showOnlyHomepageItems,
        isCompactMode = true,
        includeTags,
        includeBinaryPolls,
        experimentGroup,
        cache,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        scope?: Scope,
        /**
         * will filter items by tagID
         */
        tagId?: number,
        /**
         * will filter items by userID
         */
        userId?: number,
        /**
         * will filter items by authorUserID
         */
        authorUserId?: number,
        newsStatus?: NewsStatus,
        search?: string,
        isMedia?: boolean,
        vendor?: VideofeedVendor,
        newsCatId?: number,
        title?: string,
        year?: number,
        month?: number,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        /**
         * Do not count the total number of items. Only check if there is a next page.
         */
        checkNextPage?: boolean,
        /**
         * Return only news articles that should be part of the news list
         */
        showOnlyHomepageItems?: boolean,
        /**
         * Exclude detailed fields that are not commonly used in lists like (e.g. newsText)
         */
        isCompactMode?: boolean,
        /**
         * Return all tags for the requested news articles
         */
        includeTags?: boolean,
        /**
         * Return all binary poll info for the requested news articles
         */
        includeBinaryPolls?: boolean,
        experimentGroup?: ExperimentGroupOrRobots,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<News>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/news',
            path: {
                'contextID': contextId,
            },
            query: {
                'scope': scope,
                'tagID': tagId,
                'userID': userId,
                'authorUserID': authorUserId,
                'newsStatus': newsStatus,
                'search': search,
                'isMedia': isMedia,
                'vendor': vendor,
                'newsCatID': newsCatId,
                'title': title,
                'year': year,
                'month': month,
                'page': page,
                'perPage': perPage,
                'checkNextPage': checkNextPage,
                'showOnlyHomepageItems': showOnlyHomepageItems,
                'isCompactMode': isCompactMode,
                'includeTags': includeTags,
                'includeBinaryPolls': includeBinaryPolls,
                'experimentGroup': experimentGroup,
                'cache': cache,
            },
        });
    }
    /**
     * Create a news article
     * Create a news article and respond with the newly created article.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns News Created
     * @throws ApiError
     */
    public createNewsByDomainId({
        contextId,
        requestBody,
        cache,
    }: {
        /**
         * will be used to set the contextID of a newly created news article
         */
        contextId: number,
        requestBody: NewsActionCreate,
        cache?: boolean,
    }): CancelablePromise<News> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/news',
            path: {
                'contextID': contextId,
            },
            query: {
                'cache': cache,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get most commented news articles
     * Return a list of most commented news articles for a given contextID
     * @returns News Success
     * @throws ApiError
     */
    public getMostCommentedNewsByDomainId({
        contextId,
        experimentGroup,
        cache,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        experimentGroup?: ExperimentGroupOrRobots,
        cache?: boolean,
    }): CancelablePromise<Array<News>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/news/most-commented',
            path: {
                'contextID': contextId,
            },
            query: {
                'experimentGroup': experimentGroup,
                'cache': cache,
            },
        });
    }
    /**
     * Get most read news articles
     * Return a list of most read news articles for a given contextID
     * @returns News Success
     * @throws ApiError
     */
    public getMostReadNewsByDomainId({
        contextId,
        isMedia = false,
        experimentGroup,
        functionality,
        cache,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        isMedia?: boolean,
        experimentGroup?: ExperimentGroupOrRobots,
        functionality?: string,
        cache?: boolean,
    }): CancelablePromise<Array<News>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/news/most-read',
            path: {
                'contextID': contextId,
            },
            query: {
                'isMedia': isMedia,
                'experimentGroup': experimentGroup,
                'functionality': functionality,
                'cache': cache,
            },
        });
    }
    /**
     * Get news item by id.
     * Return a specific news item
     * @returns News Success
     * @throws ApiError
     */
    public getByIdNewsByDomainId({
        contextId,
        id,
        scope,
        experimentGroup,
        cache,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        /**
         * Get news item by id
         */
        id: number,
        scope?: Scope,
        experimentGroup?: ExperimentGroupOrRobots,
        cache?: boolean,
    }): CancelablePromise<News> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/news/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            query: {
                'scope': scope,
                'experimentGroup': experimentGroup,
                'cache': cache,
            },
        });
    }
    /**
     * Delete an existing news article
     * Delete a news article
     *
     * - Allowed roles: moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteNewsByDomainId({
        contextId,
        id,
    }: {
        /**
         * will be used to remove an existing news article
         */
        contextId: number,
        /**
         * will be used to remove an existing news article
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/news/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get news item by original ID.
     * @returns News Success
     * @throws ApiError
     */
    public getByOriginalIdNewsByDomainId({
        contextId,
        originalId,
        experimentGroup,
        cache,
    }: {
        /**
         * Filter by contextID.
         */
        contextId: number,
        /**
         * Get news item by original ID.
         */
        originalId: string,
        experimentGroup?: ExperimentGroupOrRobots,
        cache?: boolean,
    }): CancelablePromise<News> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/news/original/{originalID}',
            path: {
                'contextID': contextId,
                'originalID': originalId,
            },
            query: {
                'experimentGroup': experimentGroup,
                'cache': cache,
            },
        });
    }
    /**
     * Making sure ImageSize Enum is getting exported
     * @returns Image Success
     * @throws ApiError
     */
    public getNewsByDomainId({
        contextId,
    }: {
        contextId: number,
    }): CancelablePromise<Image> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/newsimageSize',
            path: {
                'contextID': contextId,
            },
        });
    }
    /**
     * Increase the news visit count.
     * @returns void
     * @throws ApiError
     */
    public increaseVisitCountNewsByDomainId({
        contextId,
        newsId,
        experimentGroup,
    }: {
        contextId: number,
        newsId: number,
        /**
         * Increase the visit count for the experiment.
         */
        experimentGroup?: ExperimentGroup,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/news/{newsID}/visit',
            path: {
                'contextID': contextId,
                'newsID': newsId,
            },
            query: {
                'experimentGroup': experimentGroup,
            },
        });
    }
}
