/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OddsProvider {
    UNIBET = 'unibet',
    JACKS = 'jacks',
    TOTO = 'toto',
    DUMMY = 'dummy',
}
