/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NotificationBarCategory {
    REGULAR = 'regular',
    BETTING = 'betting',
    BREAKING = 'breaking',
    PODCAST = 'podcast',
    LIVE = 'live',
    COUNTDOWN = 'countdown',
}
