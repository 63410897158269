import { FC, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { useContextData } from '@common/useContextData';

declare global {
    interface Window {
        fbq?: (type: string, name: string, options?: any) => void;
    }
}

export const pageview = () => {
    if (typeof window === 'undefined' || typeof window.fbq === 'undefined') return;
    window.fbq('track', 'PageView');
};

export const event = (name: string, options = {}) => {
    if (typeof window === 'undefined' || typeof window.fbq === 'undefined') return;
    window.fbq('track', name, options);
};

interface Props {}

export const FacebookPixel: FC<Props> = () => {
    const contextData = useContextData();
    const router = useRouter();

    const hasFacebookPixel = contextData.context.fbPixel;
    useEffect(() => {
        if (!hasFacebookPixel) return;

        pageview();
        router.events.on('routeChangeComplete', pageview);
        return () => {
            router.events.off('routeChangeComplete', pageview);
        };
    }, [hasFacebookPixel, router.events]);

    if (!hasFacebookPixel) return null;
    return (
        <>
            <Head>
                <noscript>
                    {
                        // eslint-disable-next-line @next/next/no-img-element
                        <img
                            alt=""
                            height="1"
                            width="1"
                            style={{ display: 'none' }}
                            src={`https://www.facebook.com/tr?id=${contextData.context.fbPixel}&ev=PageView&noscript=1`}
                        />
                    }
                </noscript>
            </Head>
            <Script
                id="fb-pixel"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${contextData.context.fbPixel});
              `,
                }}
            />
        </>
    );
};
