/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BinaryPollVote } from '../models/BinaryPollVote';
import type { NewsThumb } from '../models/NewsThumb';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BinaryPollService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Vote yes/up/like/believe or no/down/dislike/disbelieve on an article
     * Vote yes/up/like/believe or no/down/dislike/disbelieve on an article based on IP
     * @returns NewsThumb Created
     * @throws ApiError
     */
    public voteBinaryPollByDomainId({
        contextId,
        newsId,
        direction,
    }: {
        contextId: number,
        /**
         * The newsID for the vote
         */
        newsId: number,
        /**
         * The "direction" of the vote (up/down)
         */
        direction: BinaryPollVote,
    }): CancelablePromise<NewsThumb> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/binary-poll/{newsID}/vote/{direction}',
            path: {
                'contextID': contextId,
                'newsID': newsId,
                'direction': direction,
            },
        });
    }
}
