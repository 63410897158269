/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TmpFile } from '../models/TmpFile';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FileService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Upload a file.
     * Uploads the file and responds with identifier to later use the file.
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns TmpFile Success
     * @throws ApiError
     */
    public fileUploadFileUpload({
        formData,
    }: {
        formData: {
            file: Blob;
        },
    }): CancelablePromise<TmpFile> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/file-upload',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `<File too long | Too many parts | Too many files | Field name too long | Field value too long | Too many fields | Unexpected field>  [fieldName] Example: File too long file1`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
