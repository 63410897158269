/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocaleID } from '../models/LocaleID';
import type { NotificationBar } from '../models/NotificationBar';
import type { NotificationBarActionCreateActionCreate } from '../models/NotificationBarActionCreateActionCreate';
import type { NotificationBarActionPatch } from '../models/NotificationBarActionPatch';
import type { NotificationBarOrderByOption } from '../models/NotificationBarOrderByOption';
import type { Pagination } from '../models/Pagination';
import type { PlatformID } from '../models/PlatformID';
import type { Scope } from '../models/Scope';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotificationBarService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all notification bar items
     * Return a list of notification bar items for a given contextID or platform and locale
     * @returns any Success
     * @throws ApiError
     */
    public listNotificationBarByDomainId({
        contextId,
        scope,
        orderBy,
        active,
        page,
        perPage,
        cache,
    }: {
        /**
         * will return items with contextID null or the current contextID
         */
        contextId: number,
        scope?: Scope,
        /**
         * Order items by priority or startTime.
         */
        orderBy?: Array<NotificationBarOrderByOption>,
        active?: boolean,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<NotificationBar>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/notification-bar',
            path: {
                'contextID': contextId,
            },
            query: {
                'scope': scope,
                'orderBy': orderBy,
                'active': active,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
        });
    }
    /**
     * Create a notification bar item
     * Create a notification bar item and respond with the newly created item.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns NotificationBar Created
     * @throws ApiError
     */
    public createNotificationBarByDomainId({
        contextId,
        requestBody,
        cache,
    }: {
        /**
         * will be used to set the contextID of a newly created notification bar
         */
        contextId: number,
        requestBody: NotificationBarActionCreateActionCreate,
        cache?: boolean,
    }): CancelablePromise<NotificationBar> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/notification-bar',
            path: {
                'contextID': contextId,
            },
            query: {
                'cache': cache,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update a notification bar item
     * Update an existing notification bar item. The response is an updated version of the updated notification bar item
     *
     * - Allowed roles: moderator, editor, admin
     * @returns NotificationBar Success
     * @throws ApiError
     */
    public patchNotificationBarByDomainId({
        contextId,
        id,
        requestBody,
    }: {
        /**
         * will be used to update a specific notification bar item
         */
        contextId: number,
        /**
         * will be used to update a specific notification bar item
         */
        id: number,
        requestBody: NotificationBarActionPatch,
    }): CancelablePromise<NotificationBar> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/notification-bar/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Delete an existing notification bar item
     * Deletes and returns 204 or 404 depending if the request succeeds
     *
     * - Allowed roles: moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteNotificationBarByDomainId({
        contextId,
        id,
    }: {
        /**
         * will be used to remove an existing notification bar item
         */
        contextId: number,
        /**
         * will be used to remove an existing notification bar item
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/notification-bar/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get all notification bar items
     * Return a list of notification bar items for a given contextID or platform and locale
     * @returns any Success
     * @throws ApiError
     */
    public listNotificationBarByPlatformLocale({
        platform,
        locale,
        scope,
        orderBy,
        active,
        page,
        perPage,
        cache,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        /**
         * will return items for a specific locale
         */
        locale: LocaleID,
        scope?: Scope,
        /**
         * Order items by priority or startTime.
         */
        orderBy?: Array<NotificationBarOrderByOption>,
        active?: boolean,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<NotificationBar>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/locale/{locale}/notification-bar',
            path: {
                'platform': platform,
                'locale': locale,
            },
            query: {
                'scope': scope,
                'orderBy': orderBy,
                'active': active,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
        });
    }
    /**
     * Create a notification bar item
     * Create a notification bar item and respond with the newly created item.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns NotificationBar Created
     * @throws ApiError
     */
    public createNotificationBarByPlatformLocale({
        platform,
        locale,
        requestBody,
        cache,
    }: {
        platform: PlatformID,
        locale: LocaleID,
        requestBody: NotificationBarActionCreateActionCreate,
        cache?: boolean,
    }): CancelablePromise<NotificationBar> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/platform/{platform}/locale/{locale}/notification-bar',
            path: {
                'platform': platform,
                'locale': locale,
            },
            query: {
                'cache': cache,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update a notification bar item
     * Update an existing notification bar item. The response is an updated version of the updated notification bar item
     *
     * - Allowed roles: moderator, editor, admin
     * @returns NotificationBar Success
     * @throws ApiError
     */
    public patchNotificationBarByPlatformLocale({
        platform,
        locale,
        id,
        requestBody,
    }: {
        platform: PlatformID,
        locale: LocaleID,
        /**
         * will be used to update a specific notification bar item
         */
        id: number,
        requestBody: NotificationBarActionPatch,
    }): CancelablePromise<NotificationBar> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/platform/{platform}/locale/{locale}/notification-bar/{id}',
            path: {
                'platform': platform,
                'locale': locale,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Delete an existing notification bar item
     * Deletes and returns 204 or 404 depending if the request succeeds
     *
     * - Allowed roles: moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteNotificationBarByPlatformLocale({
        platform,
        locale,
        id,
    }: {
        platform: PlatformID,
        locale: LocaleID,
        /**
         * will be used to remove an existing notification bar item
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/platform/{platform}/locale/{locale}/notification-bar/{id}',
            path: {
                'platform': platform,
                'locale': locale,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
