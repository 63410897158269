import { dot } from 'dot-object';
import newrelic from 'newrelic';

import { ApiError } from '@common/clients/api/core/ApiError';
import { logger } from '@common/logger';

type OnErrorMethod = (err: Error) => void;

class NotFound extends ApiError {
    constructor({ request, url, status, statusText, body, message }: ApiError) {
        super(request, { url, status, statusText, body, ok: false }, `${message} - ${body?.message}`);
        this.name = 'NotFoundError';
    }
}

const WARNING_STATUSES = new Set([300, 401, 403, 404]);

export const generateCatch = <T>(defaultValue: T, onError: OnErrorMethod | undefined = undefined) => {
    return (err: any): T => {
        const isServerSide = typeof window === 'undefined' || window.navigator.appVersion === 'undefined';

        if (err instanceof ApiError) {
            const attributes = dot(
                JSON.parse(JSON.stringify({ request: err.request, 'request.fullUrl': err.url })),
            );

            if (err?.status && WARNING_STATUSES.has(err.status)) {
                logger.warn(err, attributes);
            } else {
                logger.error(err, attributes);
            }

            // Log error to newrelic
            if (isServerSide) {
                if (err?.status === 404) {
                    newrelic.noticeError(new NotFound(err), attributes, true);
                } else {
                    newrelic.noticeError(err, attributes);
                }
            }

            if (onError) {
                onError(err);
            }
        } else if (err instanceof Error) {
            logger.error(err);
            if (isServerSide) newrelic.noticeError(err);
            if (onError) onError(err);
        }
        return defaultValue;
    };
};

export const defaultCatch = generateCatch(undefined);
export const defaultArrayCatch = generateCatch([]);
