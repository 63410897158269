/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GrandPrix } from '../models/GrandPrix';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GrandPrixService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get the upcoming Grand Prix
     * Return the upcoming Grand Prix.
     * @returns GrandPrix Success
     * @throws ApiError
     */
    public upcomingGrandPrixByDomainId({
        contextId,
        days,
    }: {
        /**
         * will return items with contextID null or the current contextID
         */
        contextId: number,
        /**
         * filter on the amount of days that the next upcoming gp may be in
         */
        days?: number,
    }): CancelablePromise<GrandPrix> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/grand-prix/upcoming',
            path: {
                'contextID': contextId,
            },
            query: {
                'days': days,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
}
