import Head from 'next/head';
import { useTheme } from 'next-themes';

import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

export const FaviconLinks = () => {
    const { env, platform } = useContextData();
    const { forcedTheme } = useTheme();

    const maskColor = platform.id === PlatformID.BR ? '#00CCFF' : '#FFFFFF';

    const platformFileName: string = forcedTheme || 'vp';

    return (
        <Head>
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`${env.NEXT_PUBLIC_ASSET_PREFIX}/images/${platformFileName}/favicon/apple-touch-icon.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={`${env.NEXT_PUBLIC_ASSET_PREFIX}/images/${platformFileName}/favicon/favicon-32x32.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={`${env.NEXT_PUBLIC_ASSET_PREFIX}/images/${platformFileName}/favicon/favicon-16x16.png`}
            />
            <link
                rel="mask-icon"
                href={`${env.NEXT_PUBLIC_ASSET_PREFIX}/images/${platformFileName}/favicon/safari-pinned-tab.svg`}
                color={maskColor}
            />
            <meta name="msapplication-TileColor" content={maskColor} />
            <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        </Head>
    );
};
