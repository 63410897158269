import { FC, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

import { LocaleID, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

interface Props {}

const subscribeToLocaleChange = (locale: LocaleID) => {
    const language = locale.replace(/^(\w{2}).+$/, '$1');
    OneSignal.User.addTag('language', language);
};

const getInitConfig = (oneSignalID: string) => {
    const isDev = process.env.NODE_ENV === 'development';
    return {
        appId: oneSignalID,
        allowLocalhostAsSecureOrigin: isDev,
        serviceWorkerPath: '/OneSignalSDKWorker.js',
    };
};

export const OneSignalTracker: FC<Props> = () => {
    const { platform, context } = useContextData();
    const { onesignalID, locale } = context;

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (onesignalID && !initialized) {
                const config = getInitConfig(onesignalID);
                OneSignal.init(config).then(() => {
                    OneSignal.Slidedown.promptPush().then(() => {
                        if (platform.id === PlatformID.GP) {
                            subscribeToLocaleChange(locale);
                        }
                        setInitialized(true);
                    });
                });
            }
        }
    });

    return <></>;
};
