/**
 * WARN: Only use this function on the client-side. Use the `isEnabled` constant on the server-side.
 */
export const getIsEnabled = (env: Record<string, string | undefined>) =>
    ['production', 'testing'].indexOf(env.APP_ENV || env.NODE_ENV || '') === -1 && env.NODE_ENV !== 'test';

/**
 * WARN: Only use this constant on the server-side. Use `getIsEnabled(contextData.env)` on the client-side.
 */
export const isEnabled = getIsEnabled(process.env);
