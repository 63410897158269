/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MatchPhase {
    N_A = 'n-a',
    CALLED_OFF = 'called-off',
    STOPPED_DURING_GAME = 'stopped-during-game',
    DELAYED = 'delayed',
    PENALTIES = 'penalties',
    HALF_TIME = 'half-time',
    AFTER_PENALTIES = 'after-penalties',
    AFTER_EXTRA_TIME = 'after-extra-time',
    FULL_TIME = 'full-time',
}
