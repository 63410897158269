import Session from 'supertokens-auth-react/recipe/session';
import { UserRoleClaim } from 'supertokens-web-js/recipe/userroles';

import { UserRole } from '@common/clients/api';
import { ResolvedContextData } from '@common/defaults';
import { getIsEnabled } from '@common/supertokens/isEnabled';

interface LoggedInStatus {
    isLoggedIn: boolean;
    isLoading: boolean;
    isUnverified: boolean;
    userRole?: UserRole;
}

const isClientSide = () => typeof window !== 'undefined';
export const useLoggedInStatus = (contextData: ResolvedContextData): LoggedInStatus => {
    if (!isClientSide() || !getIsEnabled(contextData.env) || !!contextData.userID) {
        return {
            isLoggedIn: !!contextData.userID,
            isLoading: false,
            isUnverified: contextData.user?.role === UserRole.USER_NOT_VALIDATED,
            userRole: contextData.user?.role,
        };
    }

    const claimValue = Session.useClaimValue(UserRoleClaim);
    const userRole = claimValue.loading ? undefined : (claimValue.value?.[0] as UserRole | undefined);
    const isUnverified = userRole === UserRole.USER_NOT_VALIDATED;

    const isLoggedIn = userRole
        ? [UserRole.USER, UserRole.EDITOR, UserRole.MODERATOR, UserRole.ADMIN, UserRole.USER_BANNED].includes(
              userRole,
          )
        : false;

    return {
        isLoggedIn,
        isLoading: claimValue.loading,
        isUnverified,
        userRole: userRole,
    };
};
