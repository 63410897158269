/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ModuleScheduleOrderByOptions {
    START_TIME = 'startTime',
    END_TIME = 'endTime',
    DURATION = 'duration',
    MODULE = 'module',
    BOOKMAKER_ID = 'bookmakerID',
}
