/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class StatusService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get the status of the server.
     * It checks if it can connect to the cache(s) and database(s). If the last successful check was less than a second ago, it immediately returns successfully.
     * @returns string Success
     * @throws ApiError
     */
    public getStatus(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/status',
        });
    }
}
