import { IncomingMessage } from 'http';

import { ExperimentGroupOrRobots } from '@common/clients/api';

// Fixme: PB-6092 - Make sure list of crawlers/robots regex is up-to-date
const ROBOT_CHECK_REGEX =
    /adidxbotc|Applebot\/|archive.org_bot|asterias\/|Baiduspider\/|bingbot\/|BingPreview\/|DuckDuckBot\/|FAST-WebCrawler\/|Feedspot|Feedspotbot\/|Google Page Speed Insights|Google PP|Google Search Console|Google Web Preview|Googlebot|Google-SearchByImage|Google-Structured-Data-Testing-Tool|Chrome-Lighthouse|heritrix\/|iaskspider\/|Mediapartners-Google|msnbot|PTST\/|SEMrushBot|special_archiver\/|Siteimprove|Y!J-ASR\/|Y!J-BRI\/|Y!J-BRJ\/YATS|Y!J-BRO\/YFSJ|Y!J-BRW\/|Y!J-BSC\/|Yahoo|aabot\/|compatible; aa\/|PetalBot\/|Prerender|MetadataScraper|Slackbot|Twitterbot|facebookexternalhit\/|Web-Crawler|Ubuntu\sChromium|ias-ie|Verity\/|GrapeshotCrawler\/|SeoBot\/|SentiBot\/|DataForSeoBot\/|GPTBot\/|WhatsApp\/|trendictionbot/;

interface Cookies {
    cookies: Partial<{ [key: string]: string }>;
}

type GetExperimentGroup = (args: IncomingMessage & Cookies) => ExperimentGroupOrRobots;

export const getExperimentGroup: GetExperimentGroup = ({ cookies, headers }) => {
    const userAgent = cookies?.userAgent || headers['user-agent'] || '';

    return ROBOT_CHECK_REGEX.test(userAgent)
        ? ExperimentGroupOrRobots.ROBOTS
        : Math.round(Math.random()) % 2 === 0
          ? ExperimentGroupOrRobots.A
          : ExperimentGroupOrRobots.B;
};
