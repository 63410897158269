/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TournamentRanking } from '../models/TournamentRanking';
import type { TournamentStandings } from '../models/TournamentStandings';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TournamentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get tournament standings
     * Get tournament standings
     * @returns TournamentStandings Success
     * @throws ApiError
     */
    public getTournamentStandingsTournament({
        id,
    }: {
        /**
         * OPTA ID of the requested tournament
         */
        id: string,
    }): CancelablePromise<TournamentStandings> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/tournament/{id}/standings',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get tournament rankings
     * Get tournament rankings
     * @returns TournamentRanking Success
     * @throws ApiError
     */
    public getTournamentRankingsTournament({
        id,
        limit = 5,
    }: {
        /**
         * OPTA ID of the requested tournament
         */
        id: string,
        limit?: number,
    }): CancelablePromise<Array<TournamentRanking>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/football/tournament/{id}/rankings',
            path: {
                'id': id,
            },
            query: {
                'limit': limit,
            },
        });
    }
}
