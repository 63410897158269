/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MatchPeriod {
    FIRST_HALF = 'first-half',
    SECOND_HALF = 'second-half',
    EXTRA_TIME_FIRST_HALF = 'extra-time-first-half',
    EXTRA_TIME_SECOND_HALF = 'extra-time-second-half',
    PENALTIES = 'penalties',
    FIRST_BREAK = 'first-break',
    SECOND_BREAK = 'second-break',
    THIRD_BREAK = 'third-break',
    FOURTH_BREAK = 'fourth-break',
    FINISHED = 'finished',
    NOT_STARTED = 'not-started',
}
