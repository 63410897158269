import { FC } from 'react';
import Head from 'next/head';

export interface Props {
    data: any;
    entity: string;
}

export const StructuredData: FC<Props> = ({ data, entity }) => {
    return (
        <Head>
            <script
                key={entity}
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
            />
        </Head>
    );
};
