/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CompetitionType {
    UNKNOWN = 'unknown',
    DOMESTIC_LEAGUE = 'domestic-league',
    DOMESTIC_CUP = 'domestic-cup',
    DOMESTIC_SUPER_CUP = 'domestic-super-cup',
    INTERNATIONAL_CUP = 'international-cup',
    INTERNATIONAL_SUPER_CUP = 'international-super-cup',
}
