/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewsThumb } from '../models/NewsThumb';
import type { Pagination } from '../models/Pagination';
import type { PlatformID } from '../models/PlatformID';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CommentLikeService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create a like
     * Like a comment as a logged in user.
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns NewsThumb Created
     * @throws ApiError
     */
    public createLikeCommentByDomainId({
        contextId,
        commentId,
    }: {
        contextId: number,
        /**
         * Will be used to create a like
         */
        commentId: number,
    }): CancelablePromise<NewsThumb> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/comment/{commentID}/like',
            path: {
                'contextID': contextId,
                'commentID': commentId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Delete a like from a comment
     * Deletes and returns 204 or 404 depending if the request succeeds
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteLikeCommentByDomainId({
        contextId,
        commentId,
    }: {
        contextId: number,
        /**
         * Will be used to remove a like
         */
        commentId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/comment/like/{commentID}',
            path: {
                'contextID': contextId,
                'commentID': commentId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get articles thumbs by userID
     * Return a list of newsThumbs of an article based on userID.
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public listLikeCommentByDomainId({
        contextId,
        newsId,
        userId,
    }: {
        contextId: number,
        newsId: number,
        userId: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<NewsThumb>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/comment/{newsID}/{userID}/user-likes',
            path: {
                'contextID': contextId,
                'newsID': newsId,
                'userID': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Create a like
     * Like a comment as a logged in user.
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns NewsThumb Created
     * @throws ApiError
     */
    public createLikeCommentByPlatform({
        platform,
        commentId,
    }: {
        platform: PlatformID,
        /**
         * Will be used to create a like
         */
        commentId: number,
    }): CancelablePromise<NewsThumb> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/platform/{platform}/comment/{commentID}/like',
            path: {
                'platform': platform,
                'commentID': commentId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Delete a like from a comment
     * Deletes and returns 204 or 404 depending if the request succeeds
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteLikeCommentByPlatform({
        platform,
        commentId,
    }: {
        platform: PlatformID,
        /**
         * Will be used to remove a like
         */
        commentId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/platform/{platform}/comment/like/{commentID}',
            path: {
                'platform': platform,
                'commentID': commentId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get articles thumbs by userID
     * Return a list of newsThumbs of an article based on userID.
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public listLikeCommentByPlatform({
        platform,
        newsId,
        userId,
    }: {
        platform: PlatformID,
        newsId: number,
        userId: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<NewsThumb>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/comment/{newsID}/{userID}/user-likes',
            path: {
                'platform': platform,
                'newsID': newsId,
                'userID': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
