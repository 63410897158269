/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LocaleID {
    BG = 'bg',
    CS = 'cs',
    DA = 'da',
    DE = 'de',
    EL = 'el',
    EN = 'en',
    ES = 'es',
    ES_ES = 'es_ES',
    ET = 'et',
    FI = 'fi',
    FR = 'fr',
    HU = 'hu',
    ID = 'id',
    IT = 'it',
    JA = 'ja',
    LT = 'lt',
    LV = 'lv',
    NL_BE = 'nl_BE',
    NL_NL = 'nl_NL',
    PL = 'pl',
    PT_BR = 'pt_BR',
    PT_PT = 'pt_PT',
    RO = 'ro',
    RU = 'ru',
    SK = 'sk',
    SL = 'sl',
    SV = 'sv',
    TR = 'tr',
    ZH_CN = 'zh_CN',
}
