/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EventType {
    GOAL = 'goal',
    SUBSTITUTION = 'substitution',
    PENALTY_MISSED = 'penalty-missed',
    CARD = 'card',
    STAGE = 'stage',
    SHOOTOUT_PENALTY = 'shootout-penalty',
}
