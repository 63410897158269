import { FC, memo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import Script from 'next/script';

import { AdPlacement } from '@common/clients/api';

interface Props {
    placement: AdPlacement;
    html: string;
    isDocumentPlacement?: boolean;
}

/**
 * There will be several ad embedded html formats:
 * 1:
 *      <div id="id_to_target"></div>
 *      <script async src="https://www.3rdparty.com/external_library.js"></script>
 *      <script>
 *          (function(){})();
 *       </script>
 *
 * 2.
 *          <div id="id_target">
 *              <script>
 *                  window.googletag = window.googletag || {cmd: []};
 *
 *                  // code
 *
 *                  googletag.enableServices();
 *                  googletag.display('id_target');
 *                  });
 *                  </script>
 *              </div>
 *
 * 3.
 *
 * <div id="sticky_target" style="background: blue;"></div>
 *     <div id="id_target_2"></div>
 *
 *
 * 4. inline styles with nested elements and scripts
 *
 * <style>
 *     .ad-class {
 *         // style rules
 *     }
 * </style>
 * <center>
 *     <div id="videoAd" style="background: #f5f5f5;">
 *         <script type="text/javascript" language="javascript" src="https://live.primis.tech/live/liveView.php?s=110247&schain=1.0,1!snack-media.com,SNM_2804,1"></script>
 *    </div>
 *          <div id="belowVideoAd" style="background: #f5f5f5;">
 *              <div id="another_targeting_id"></div>
 *          </div>
 *      </center>
 *
 *  5. just a inline script
 *  <script>
 *      (function(){})();
 *      </script>
 */

export const generateOptions = (
    placement: AdPlacement,
    isDocumentPlacement: boolean,
): HTMLReactParserOptions => ({
    transform: (reactNode, domNode, index) => {
        if (!('name' in domNode) || !('attribs' in domNode)) return reactNode as JSX.Element;

        switch (domNode.name) {
            case 'script':
                const id = `ad-script-${placement}-${index}`;
                const scriptAttributes = domNode.attribs;

                if (scriptAttributes['src']) {
                    return isDocumentPlacement ? (
                        // eslint-disable-next-line @next/next/no-sync-scripts
                        <script id={id} key={id} src={scriptAttributes['src']} {...scriptAttributes} />
                    ) : (
                        <Script
                            id={id}
                            key={id}
                            src={scriptAttributes['src']}
                            strategy="lazyOnload"
                            {...scriptAttributes}
                        />
                    );
                }
                const scriptContentNode = domNode.children?.at(0);
                const scriptContentData =
                    scriptContentNode && 'data' in scriptContentNode ? scriptContentNode.data : undefined;

                if (scriptContentData) {
                    return isDocumentPlacement ? (
                        <script
                            id={id}
                            key={id}
                            {...scriptAttributes}
                            dangerouslySetInnerHTML={{ __html: scriptContentData }}
                        />
                    ) : (
                        <Script id={id} key={id} strategy="lazyOnload">
                            {scriptContentData}
                        </Script>
                    );
                }
                return renderToStaticMarkup(<>{reactNode}</>);
            case 'style':
                const styleNode = domNode.children?.at(0);
                const inlineStyle = styleNode && 'data' in styleNode ? styleNode.data : undefined;
                return <style key={`style-${index}`}>{inlineStyle}</style>;
            default:
                return reactNode as JSX.Element;
        }
    },
});

const Component: FC<Props> = ({ placement, html, isDocumentPlacement }) => {
    const parsedHtml = parse(
        html,
        generateOptions(placement, isDocumentPlacement ? isDocumentPlacement : false),
    );
    return parsedHtml;
};

Component.displayName = 'AdHtmlToReact';
export const AdHtmlToReact = memo(Component);
