import { BaseHttpRequest, CancelablePromise } from '@common/clients/api';
import { ApiRequestOptions } from '@common/clients/api/core/ApiRequestOptions';
import { request as __request } from '@common/clients/request/request';
import { RequestConfig } from '@common/clients/request/RequestConfig';
import { getEnvVars } from '@common/envvars';

const DEFAULT_TIMEOUT = 30_000;

export class ApiFromCronBaseRequest extends BaseHttpRequest {
    requestConfig: RequestConfig | undefined;

    constructor(apiHost: string, requestConfig: RequestConfig = {}) {
        const envVarsSearch = {
            AUTH_CRON_NAME: true,
            AUTH_CRON_PASSWORD: true,
        };

        const envValues = getEnvVars(envVarsSearch) as {
            AUTH_CRON_NAME: string;
            AUTH_CRON_PASSWORD: string;
        };

        super({
            BASE: apiHost,
            VERSION: '1.0.0',
            WITH_CREDENTIALS: false,
            CREDENTIALS: 'omit',
            HEADERS: {
                auth_app_name: envValues.AUTH_CRON_NAME,
                auth_app_password: envValues.AUTH_CRON_PASSWORD,
            },
        });

        requestConfig.maxRetries = requestConfig.maxRetries ?? 0;
        this.requestConfig = requestConfig;
    }

    public request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        const extendedConfig = {
            ...this.config,
            TIMEOUT: DEFAULT_TIMEOUT,
        };

        return __request(extendedConfig, options, undefined, this.requestConfig);
    }
}
