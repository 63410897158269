/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewsCategory } from '../models/NewsCategory';
import type { NewsCategoryActionPatch } from '../models/NewsCategoryActionPatch';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NewsCategoryService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get news categories
     * Return a list of news categories for a given contextID
     * @returns NewsCategory Success
     * @throws ApiError
     */
    public listNewsCategoryByDomainId({
        contextId,
        nameSlug,
        isMedia,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        /**
         * will filter items by nameSlug
         */
        nameSlug?: string,
        /**
         * will filter newsCategories that are video categories or general categories
         */
        isMedia?: boolean,
    }): CancelablePromise<Array<NewsCategory>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/news-category',
            path: {
                'contextID': contextId,
            },
            query: {
                'nameSlug': nameSlug,
                'isMedia': isMedia,
            },
        });
    }
    /**
     * Get news category by slug.
     * Get news category by slug.
     * @returns NewsCategory Success
     * @throws ApiError
     */
    public getBySlugNewsCategoryByDomainId({
        contextId,
        slug,
        isMedia,
    }: {
        contextId: number,
        /**
         * Get news categor by slug
         */
        slug: string,
        /**
         * will filter newsCategories that are video categories or general categories
         */
        isMedia?: boolean,
    }): CancelablePromise<NewsCategory> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/news-category/{slug}',
            path: {
                'contextID': contextId,
                'slug': slug,
            },
            query: {
                'isMedia': isMedia,
            },
        });
    }
    /**
     * Get news category by functionality.
     * Get news category by functionality.
     * @returns NewsCategory Success
     * @throws ApiError
     */
    public getByFunctionalityNewsCategoryByDomainId({
        contextId,
        functionality,
    }: {
        contextId: number,
        /**
         * Get news category by functionality
         */
        functionality: string,
    }): CancelablePromise<NewsCategory> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/news-category/functionality/{functionality}',
            path: {
                'contextID': contextId,
                'functionality': functionality,
            },
        });
    }
    /**
     * Update news category
     * Update news category
     * @returns NewsCategory Success
     * @throws ApiError
     */
    public patchNewsCategoryByDomainId({
        contextId,
        id,
        requestBody,
    }: {
        contextId: number,
        /**
         * will be used to update a specific category
         */
        id: number,
        /**
         * will be used to update a specific category
         */
        requestBody: NewsCategoryActionPatch,
    }): CancelablePromise<NewsCategory> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/news-category/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
