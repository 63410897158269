import { BaseLayout, colorize, Layout, LOG_COLORS, LogEvent } from '@tsed/logger';
import Util from 'util';

const orderedObject = <T extends object>(data: T): T => {
    return Object.keys(data)
        .sort()
        .reduce<T>((obj, key) => {
            // @ts-ignore
            obj[key] = data[key];
            return obj;
        }, {} as T);
};

// Code based off of https://github.com/tsedio/logger/blob/production/packages/logger/src/common/layouts/utils/logEventToObject.ts but with correct handling of error objects
export function logEventToObject(loggingEvent: LogEvent) {
    const log: any = {
        ...loggingEvent.context.toJSON(),
        startTime: loggingEvent.startTime,
        categoryName: loggingEvent.categoryName,
        level: loggingEvent.level.toString(),
    };

    log.data = loggingEvent.data.reduce((acc, current) => {
        if (current instanceof Error) {
            return [...acc, current.stack];
        } else if (typeof current === 'object') {
            Object.assign(log, current);

            if (current.data) {
                return [].concat(acc, current.data);
            }

            return acc;
        }

        return [...acc, Util.format(current)];
    }, []);

    return log;
}

const isDevMode = process.env.NODE_ENV === 'development';

const getPrefix = (loggingEvent: LogEvent, mainMessage: string = ''): string => {
    if (!isDevMode) return '';
    const index = loggingEvent.level.toString();
    const color = LOG_COLORS[index as keyof typeof LOG_COLORS];
    return `${colorize(loggingEvent.formattedLevel, color)} - ${mainMessage} ${mainMessage ? ' \n' : ''}`;
};

@Layout({ name: 'next-json' })
export class NextJsonLayout extends BaseLayout {
    transform(loggingEvent: LogEvent, _timezoneOffset?: number): string {
        const log = logEventToObject(loggingEvent);

        let mainMessage: string | undefined;

        delete log.categoryName;
        if (isDevMode) delete log.level;

        // When using plain logger.debug('log this'), we probably want to read `log this` first
        if (log.data && typeof log.data[0] === 'string') {
            mainMessage = log.data[0];
            if (log.data.length === 1) {
                delete log.data;
            } else if (log.data.length === 2 && typeof log.data[1] === 'string') {
                mainMessage = `${mainMessage} - ${log.data[1]}`;
                delete log.data;
            }
        }

        // same for `NOT_FOUND: No headlines dossier was found for this domain.`
        else if (
            typeof log.body === 'object' &&
            typeof log.body.name === 'string' &&
            typeof log.body.message === 'string'
        ) {
            mainMessage = `${log.body.name}: ${log.body.message}`;
        }

        // same for `NOT_FOUND: No headlines dossier was found for this domain.`
        else if (typeof log.error_name === 'string' && typeof log.error_message === 'string') {
            mainMessage = `${log.error_name}: ${log.error_message}`;
        }

        // same for `401: POST /auth/session/refresh`
        else if (
            typeof log.status === 'number' &&
            typeof log.method === 'string' &&
            typeof log.url === 'string'
        ) {
            mainMessage = `${log.status}: ${log.method} ${log.url}`;
        }

        if (mainMessage && !isDevMode) log._ = mainMessage;

        let stringifiedValue: string;
        try {
            stringifiedValue = JSON.stringify(
                orderedObject(log),
                undefined,
                process.env.NODE_ENV === 'development' ? 4 : undefined,
            );
        } catch (err) {
            stringifiedValue = 'Failed to stringify log: ' + (err as Error)?.message;
        }

        return getPrefix(loggingEvent, mainMessage) + stringifiedValue;
    }
}
