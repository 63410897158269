/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ModuleScheduleSection {
    ARTICLE_MATCHES = 'articleMatches',
    BETTING_PAGE = 'bettingPage',
    COMMENTS = 'comments',
    NATIONAL_THEME = 'nationalTheme',
    ODDS_DOSSIER_HOME = 'oddsDossierHome',
    ODDS_MATCH_TICKER = 'oddsMatchTicker',
    ODDS_MATCHES = 'oddsMatches',
    ODDS_POLLS = 'oddsPolls',
    PROMO_ELEMENT_BELOW_ARTICLE = 'promoElementBelowArticle',
    PROMO_ELEMENT_BELOW_VIDEO_CAROUSEL = 'promoElementBelowVideoCarousel',
    PROMOTED_ARTICLE = 'promotedArticle',
    TAG_NEWS_LIST = 'tagNewsList',
}
