/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MatchStatus {
    ALL = 'all',
    FIXTURE = 'fixture',
    PLAYED = 'played',
    PLAYING = 'playing',
    CANCELLED = 'cancelled',
    POSTPONED = 'postponed',
    SUSPENDED = 'suspended',
}
