/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum GoalType {
    GOAL = 'goal',
    OWN_GOAL = 'own-goal',
    PENALTY_GOAL = 'penalty-goal',
    NO_GOAL = 'no-goal',
}
